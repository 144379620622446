export enum BitMods {
    None = 0,
    NoFail = 1,
    Easy = 2,
    TouchDevice = 4,
    Hidden = 8,
    HardRock = 16,
    SuddenDeath = 32,
    DoubleTime = 64,
    Relax = 128,
    HalfTime = 256,
    Nightcore = 512,
    Flashlight = 1024,
    Auto = 2048,
    SpunOut = 4096,
    Autopilot = 8192,
    Perfect = 16384,
    Key4 = 32768,
    Key5 = 65536,
    Key6 = 131072,
    Key7 = 262144,
    Key8 = 524288,
    FadeIn = 1048576,
    Random = 2097152,
    Cinema = 4194304,
    TargetPractice = 8388608,
    Key9 = 16777216,
    KeyCoop = 33554432,
    Key1 = 67108864,
    Key2 = 134217728,
    Key3 = 268435456,
    ScoreV2 = 536870912,
    Mirror = 1073741824,
}

export enum ModAcronym {
    NoFail = "NF",
    Easy = "EZ",
    TouchDevice = "TD",
    Hidden = "HD",
    HardRock = "HR",
    SuddenDeath = "SD",
    DoubleTime = "DT",
    Relax = "RX",
    HalfTime = "HT",
    Nightcore = "NC",
    Flashlight = "FL",
    Auto = "AUTO",
    SpunOut = "SO",
    Autopilot = "AP",
    Perfect = "PF",
    Key4 = "4K",
    Key5 = "5K",
    Key6 = "6K",
    Key7 = "7K",
    Key8 = "8K",
    FadeIn = "FI",
    Random = "RN",
    Cinema = "CN",
    TargetPractice = "TP",
    Key9 = "9K",
    KeyCoop = "COOP",
    Key1 = "1K",
    Key2 = "2K",
    Key3 = "3K",
    ScoreV2 = "V2",
    Mirror = "MR",
    Classic = "CL",
}

export enum Gamemode {
    Standard = 0,
    Taiko = 1,
    Catch = 2,
    Mania = 3,
}

export enum BeatmapStatus {
    Graveyard = -2,
    WorkInProgress = -1,
    Pending = 0,
    Ranked = 1,
    Approved = 2,
    Qualified = 3,
    Loved = 4,
}
